import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

import { useRobotStore } from 'src/stores';
import { ParkingLotRobot, QueryConfig, robotQueryKeys, RobotState, RobotStateSortOrder } from '../@types';
import { AppContext } from '../components';

const fetchRobots = async (operationsApi: AxiosInstance, robotLocationId: string) => {
  const { data } = await operationsApi.get<ParkingLotRobot[]>(`/robots`, { params: { robotLocationId } });

  // Sort robots so grounded shows up first, then by battery percentage, then by robot state, then by serial
  return data.sort((a, b) => {
    const aIsGrounded = a.robotState.robotState === RobotState.GROUNDED;
    const bIsGrounded = b.robotState.robotState === RobotState.GROUNDED;
    if (aIsGrounded && !bIsGrounded) return -1;
    if (!aIsGrounded && bIsGrounded) return 1;

    const battDiff = a.battery.currentBatteryPercentage - b.battery.currentBatteryPercentage;
    if (battDiff) return battDiff;

    const stateDiff = RobotStateSortOrder[a.robotState.robotState] - RobotStateSortOrder[b.robotState.robotState];
    if (stateDiff) return stateDiff;

    return a.serial.localeCompare(b.serial);
  });
};

interface Params {
  config?: QueryConfig<typeof fetchRobots>;
}

export const useRobots = ({ config }: Params) => {
  const { operationsApi } = AppContext.useAppContext();
  const { merchantAccessConfig } = useRobotStore();
  const robotLocationId = merchantAccessConfig?.deploy_to;

  return useQuery({
    queryKey: robotQueryKeys.merchant(robotLocationId ?? ''),
    queryFn: () => fetchRobots(operationsApi, robotLocationId ?? ''),
    enabled: !!robotLocationId,
    refetchInterval: 10000,
    ...config,
  });
};
