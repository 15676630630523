import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

import { MutationConfig, robotQueryKeys } from '../@types';
import { AppContext } from '../components';
import { useRobotStore } from '../stores';

async function deployRobot(operationsApi: AxiosInstance, serial: string, robotLocationId: string) {
  try {
    await operationsApi.post<string>(`/robots/${serial}/deploy`, { robotLocationId });
  } catch (err: unknown) {
    console.error('deployRobot', err);
    throw err;
  }
}

export function useDeployBot(undeploy: boolean, config?: MutationConfig<typeof deployRobot>) {
  const { operationsApi } = AppContext.useAppContext();
  const { robotId, merchantAccessConfig } = useRobotStore();
  return useMutation({
    mutationKey: robotQueryKeys.deploy(robotId ?? '', undeploy),
    mutationFn: () =>
      deployRobot(
        operationsApi,
        robotId ?? '',
        (undeploy ? merchantAccessConfig?.storage_location : merchantAccessConfig?.deploy_to) ?? ''
      ),
    ...config,
  });
}
