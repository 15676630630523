import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

import { MutationConfig, robotQueryKeys } from '../@types';
import { AppContext } from '../components';

const checkOutBot = async (operationsApi: AxiosInstance, serial: string) => {
  try {
    await operationsApi.post(`/fleet-management/check-out-bot`, {
      serial,
    });
  } catch (err: unknown) {
    throw err;
  }
};

export const useCheckOutBot = (serial: string, config?: MutationConfig<typeof checkOutBot>) => {
  const { operationsApi } = AppContext.useAppContext();

  return useMutation({
    mutationKey: robotQueryKeys.checkOut(serial),
    mutationFn: () => checkOutBot(operationsApi, serial),
    ...config,
  });
};
