import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

import { useRobotStore } from 'src/stores';
import { MutationConfig, robotQueryKeys } from '../@types';
import { AppContext } from '../components';

const checkInBot = async (operationsApi: AxiosInstance, serial: string, storageLocationId: string) => {
  try {
    await operationsApi.post(`/fleet-management/check-in-bot`, {
      serial,
      storageLocationId,
    });
  } catch (err: unknown) {
    console.error('checkInBot', err);
    throw err;
  }
};

export const useCheckInBot = (serial: string, config?: MutationConfig<typeof checkInBot>) => {
  const { operationsApi } = AppContext.useAppContext();
  const { merchantAccessConfig } = useRobotStore();

  return useMutation({
    mutationKey: robotQueryKeys.checkIn(serial),
    mutationFn: () => checkInBot(operationsApi, serial, merchantAccessConfig?.storage_location ?? ''),
    ...config,
  });
};
