import { AxiosError, AxiosInstance } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { robotQueryKeys } from '../@types';
import { LID_ACTIONS, LidResponse } from '../@types';
import { AppContext } from '../components';

const ALLOWED_ROBOT_STATUS = [0, 3];

export const executeToggleLidCommand = async (
  deviceApiInstance: AxiosInstance,
  serialNo: string,
  action: LID_ACTIONS
): Promise<{ success: boolean; error: string }> => {
  try {
    const { data } = await deviceApiInstance.post<LidResponse>(
      `/devices/${serialNo.toUpperCase()}/sync-commands/lid`,
      {
        state: action,
        source: 'merchant-ui',
      },
      { timeout: 30000 }
    );

    if (ALLOWED_ROBOT_STATUS.includes(+data.status)) return { success: true, error: '' };

    return { success: false, error: 'Robot is not in a valid state' };
  } catch (err) {
    console.error("executeToggleLidCommand: Couldn't toggle lid", err);
    return { success: false, error: (err as AxiosError).message };
  }
};

export const useToggleRobotLid = (serialNo: string, config?: Record<string, unknown>) => {
  const { deviceApi } = AppContext.useAppContext();

  return useMutation({
    mutationKey: robotQueryKeys.toggle(serialNo),
    mutationFn: (action: LID_ACTIONS) => executeToggleLidCommand(deviceApi, serialNo, action),
    onSuccess: ({ success }, action) => {
      if (success) {
        toast(
          <span>
            Successfully {action === LID_ACTIONS.CLOSE ? 'closed' : 'opened'} lid for {serialNo}
          </span>,
          { toastId: 'toggled-lid', type: 'success' }
        );
      } else {
        toast(
          <span>
            Failed to {action} lid for {serialNo}
          </span>,
          { toastId: 'failed-to-toggle-lid', type: 'error' }
        );
      }
    },
    ...config,
  });
};
