import segmentPlugin from '@analytics/segment';
import Analytics from 'analytics';

import { NEXT_PUBLIC_SEGMENT_MERCHANT_FLEET_WRITE_KEY } from './env';

const segmentInstance = Analytics({
  plugins: [
    segmentPlugin({
      writeKey: NEXT_PUBLIC_SEGMENT_MERCHANT_FLEET_WRITE_KEY,
    }),
  ],
});

export const trackSegment = segmentInstance.track;

export const identifySegment = segmentInstance.identify;
