import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { useWindowDimensions } from 'src/hooks';

interface Props {
  children: ReactNode;
}

const Container = styled.div(
  {
    margin: '0px auto',
    paddingLeft: 30,
    paddingRight: 30,
    width: '100%',
  },
  ({ style }) => ({ ...style })
);

const PageContainer = ({ children }: Props) => {
  const { width } = useWindowDimensions();
  return <Container style={{ maxWidth: Math.min(width - 60, 1000) }}>{children}</Container>;
};

export default PageContainer;
