import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

import { ParkingLotRobot, QueryConfig, robotQueryKeys } from 'src/@types';
import { AppContext } from 'src/components';
import { useRobotStore } from 'src/stores';

const getRobot = async (operationsApi: AxiosInstance, serial: string) => {
  const { data } = await operationsApi.get<ParkingLotRobot[]>(`/robots`, { params: { serial } });
  return data.length ? data[0] : null;
};

interface LoadRobotParams {
  config?: QueryConfig<typeof getRobot>;
}

export const useLoadRobot = ({ config }: LoadRobotParams) => {
  const { operationsApi } = AppContext.useAppContext();
  const { robotId } = useRobotStore();

  const serialNo = robotId ?? '';
  return useQuery({
    queryKey: robotQueryKeys.load(serialNo),
    queryFn: () => getRobot(operationsApi, serialNo),
    enabled: !!serialNo,
    refetchInterval: 5000,
    ...config,
  });
};
